<template>
  <div>
    <PageHeader />
    <b-overlay :show="isFetching">
      <b-card>
        <ValidationObserver
          ref="promotionForm"
          v-slot="{ handleSubmit, failed }"
        >
          <b-toast :visible="failed" title="Warning" variant="warning">
            {{ $t('messages.invalid_form') }}
          </b-toast>
          <form autocomplete="off" @submit.prevent="handleSubmit(onSubmit)">
            <b-form-group
                v-if="isOwner"
                label-cols-sm="12"
                label-cols-lg="2"
                label="เลือก Master"
                label-for="master"
            >
              <MasterSelectInput
                  :value="masterId"
                  hide-label
                  @update="onMasterIdChange"
              />
            </b-form-group>
            <b-form-group
                v-if="isOwner || isMaster"
                label-cols-sm="12"
                label-cols-lg="2"
                label="เลือก Agent"
                label-for="agent"
            >
              <AgentsSelectInput
                  :value="promotionData.agentId"
                  :master-id="masterId"
                  hide-label
                  @update="onAgentIdChange"
              />
            </b-form-group>
            <hr v-if="!isAgent" />
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('promotion.name')"
              rules="required"
            >
              <b-form-group
                label-cols-sm="12"
                label-cols-lg="2"
                :label="`${$t('promotion.name')} *`"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="promotionData.title"
                  :state="errors[0] ? false : null"
                ></b-form-input>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
                v-slot="{ errors }"
                name="description"
                rules="required"
            >
              <b-form-group
                :label="`${$t('fields.description')} *`"
                label-cols-sm="12"
                label-cols-lg="2"
              >
                <vue-editor id="description"  v-model="promotionData.description" :state="errors[0] ? false : null"></vue-editor>
              </b-form-group>
            </ValidationProvider>
            <b-form-group
                label-cols-sm="12"
                label-cols-lg="2"
                label="ประเภทเกม"
                label-for="forGameType"
            >
              <b-select
                  id="forGameType"
                  v-model="promotionData.forGameType"
                  :disabled="promotionData.type === 0"
              >
                <b-form-select-option
                    v-for="(opt, key) in gameTypeOptions"
                    :key="key"
                    :value="opt.value"
                >
                  {{
                    $te(`promotion.types.${opt.text}`)
                        ? $t(`promotion.types.${opt.text}`)
                        : opt.text
                  }}
                </b-form-select-option>
              </b-select>
            </b-form-group>
            <b-form-group
              label="รูปภาพ *"
              label-cols-sm="12"
              label-cols-lg="2"
              label-for="file">
              <b-form-file
                id="file"
                v-model="promotionData.image"
                accept="image/*"
              >
              </b-form-file>
              <b-form-text>
                <b-link
                  v-if="promotionData.image"
                  class="text-danger"
                  @click="promotionData.image = ''"
                >
                  {{ $t('buttons.remove')}}
                </b-link>
                <b-link
                  v-if="promotionId && promotionDetail.imageUrl"
                  :href="promotionDetail.imageUrl"
                  target="_blank"
                  class="text-info"
                >
                  กดดูรูปภาพ
                </b-link>
              </b-form-text>
            </b-form-group>
            <b-form-group
                label-cols-sm="12"
                label-cols-lg="2"
                :label="$t('fields.type')"
                label-for="type"
            >
              <b-select
                  id="type"
                  v-model="promotionData.type"
              >
                <b-form-select-option
                    v-for="(opt, key) in promotionOptions.filter(option => (userInfo.allowPromotionTypeList || []).includes(String(option.value)))"
                    :key="key"
                    :value="opt.value"
                >
                  {{
                    $te(`promotion.types.${opt.text}`)
                        ? $t(`promotion.types.${opt.text}`)
                        : opt.text
                  }}
                </b-form-select-option>
              </b-select>
            </b-form-group>
            <ValidationProvider
                v-if="promotionData.type === 10"
                v-slot="{ errors }"
                name="bonusName"
                rules="required"
            >
              <b-form-group
                  label-cols-sm="12"
                  label-cols-lg="2"
                  label="ชื่อกิจกรรม"
                  label-for="bonusName"
              >
                <b-form-input
                    id="bonusName"
                    v-model="promotionData.bonusName"
                    :state="errors[0] ? false : null"
                ></b-form-input>
              </b-form-group>
            </ValidationProvider>
            <b-form-group
                v-if="[8].includes(promotionData.type)"
                label-cols-sm="12"
                label-cols-lg="2"
                label="ไม่ได้เล่นนานต่อเนื่อง"
            >
              <b-input-group append="วัน">
                <b-input
                    v-model="promotionData.stackDayCount"
                    min="1"
                    type="number"
                ></b-input>
              </b-input-group>
            </b-form-group>
            <b-form-group
              v-if="promotionData.type === 4"
              label-cols-sm="12"
              label-cols-lg="2"
              label="เวลานาทีทอง"
            >
              <b-row>
                <b-col>
                  <b-form-group label="เริ่มเวลา">
                    <b-form-timepicker
                      v-model="startTimeRefill"
                      locale="en"
                      :hour12="false"
                    >
                    </b-form-timepicker>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="จบเวลา">
                    <b-form-timepicker
                      v-model="endTimeRefill"
                      locale="en"
                      :hour12="false"
                    >
                    </b-form-timepicker>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
            <ValidationProvider
                v-slot="{ errors }"
                name="จำกัดแจกต่อวัน"
                rules="min:1"
            >
              <b-form-group
                  label-cols-sm="12"
                  label-cols-lg="2"
                  label="จำกัดยูสที่รับได้ต่อวัน"
                  label-for="dailyLimit"
              >
                <b-input-group append="ยูส">
                  <b-input-group-prepend is-text>
                    <b-form-checkbox
                        ref="isDailyLimit"
                        v-model="promotionData.isDailyLimit"
                        name="isDailyLimit"
                        switch
                    >
                    </b-form-checkbox>
                  </b-input-group-prepend>

                  <b-form-input
                      id="dailyLimit"
                      v-model="promotionData.dailyLimit"
                      type="number"
                      :state="errors[0] ? false : null"
                      :disabled="!promotionData.isDailyLimit"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider
                v-if="[3,4,5,6,10].includes(promotionData.type)"
                v-slot="{ errors }"
                name="จำกัดจำนวนที่รับได้ต่อวัน"
                rules="min:1"
            >
              <b-form-group
                  label-cols-sm="12"
                  label-cols-lg="2"
                  label="จำกัดจำนวนที่รับได้ต่อวัน"
                  label-for="dailyLimitPerDay"
              >
                <b-input-group append="ครั้ง">
                  <b-input-group-prepend is-text>
                    <b-form-checkbox
                        ref="isDailyLimitPerDay"
                        v-model="promotionData.isDailyLimitPerDay"
                        name="isDailyLimitPerDay"
                        switch
                    >
                    </b-form-checkbox>
                  </b-input-group-prepend>

                  <b-form-input
                      id="dailyLimitPerDay"
                      v-model="promotionData.dailyLimitPerDay"
                      type="number"
                      :state="errors[0] ? false : null"
                      :disabled="!promotionData.isDailyLimitPerDay"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </ValidationProvider>

            <b-form-group
                label-cols-sm="12"
                label-cols-lg="2"
                label="ตั้งเวลาเปิด/ปิดรายสัปดาห์"
                label-for="dailyLimitPerDay"
            >
              <b-form-checkbox
                  v-model="promotionData.isRepeat"
                  name="isRepeatPromotion"
                  class="my-2"
                  switch
              >
                เปิดใช้งาน
              </b-form-checkbox>
              <RepeatConfig v-show="promotionData.isRepeat" v-model="promotionData.repeatConfig" />
            </b-form-group>
            <!-- AF -->
            <template v-if="userInfo && (userInfo.allowPromotionTypeList || []).includes('9') && promotionData.type !== 0">
              <hr class="my-4"/>
              <h5 class="mb-4">ค่าแนะนำจากยอดฝากรับโปร</h5>
              <b-form-group
                  label-cols-sm="12"
                  label-cols-lg="2"
                  label="เปิดใช้งาน"
                  label-for="isEnableDepositAffiliate">
                <b-form-checkbox id="isEnableDepositAffiliate" v-model="promotionData.isEnableDepositAffiliate" class="mt-2" switch />
              </b-form-group>
              <template v-if="promotionData.isEnableDepositAffiliate">
                <b-form-group
                    label-cols-sm="12"
                    label-cols-lg="2"
                    label="รูปแบบจ่ายค่าแนะนำ"
                    label-for="affiliateCommissionType">
                  <ValidationProvider
                      v-slot="{ errors }"
                      name="affiliateCommissionType"
                      rules="required"
                  >
                    <b-form-radio v-model="promotionData.affiliateCommissionType" name="affiliateCommissionType" :value="0" :state="errors[0] ? false : null">จำนวนคงที่</b-form-radio>
                    <b-form-radio v-model="promotionData.affiliateCommissionType" name="affiliateCommissionType" :value="1" :state="errors[0] ? false : null">เป็นเปอร์เซ็นต์จากยอดฝาก</b-form-radio>
                  </ValidationProvider>
                </b-form-group>

                <b-row v-for="i in promotionData.affiliateCommissionRate.length" :key="i">
                  <b-col>
                    <b-form-group
                        label-cols-sm="12"
                        :label-cols-lg="promotionData.affiliateCommissionType === 0 ? 2 : 4"
                        :label="`ค่าแนะนำที่ได้ (ขั้นที่ ${i})`"
                        :label-for="`affiliateCommissionRate-${i}`">
                      <ValidationProvider
                          v-slot="{ errors }"
                          :name="`affiliateCommissionRate-${i}`"
                          rules="required"
                      >
                        <b-input-group :append="promotionData.affiliateCommissionType === 0 ? 'เครดิต' : '%'">
                          <b-form-input
                              :id="`affiliateCommissionRate-${i}`"
                              v-model="promotionData.affiliateCommissionRate[i-1].value"
                              :state="errors[0] ? false : null"
                          ></b-form-input>
                        </b-input-group>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                  <b-col v-if="promotionData.affiliateCommissionType === 1">
                    <b-form-group
                        label-cols-sm="12"
                        label-cols-lg="4"
                        :label="`ค่าแนะนำที่ได้สูงสุด (ขั้นที่ ${i})`"
                        :label-for="`maxAffiliateCommission-${i-1}`">
                      <ValidationProvider
                          v-slot="{ errors }"
                          :name="`maxAffiliateCommission-${i-1}`"
                          rules="required"
                      >
                        <b-input-group append="เครดิต">
                          <b-input
                              :id="`maxAffiliateCommission-${i-1}`"
                              v-model="promotionData.maxAffiliateCommission[i-1].value"
                              :state="errors[0] ? false : null"
                          ></b-input>
                        </b-input-group>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="1">
                    <b-button
                        :disabled="i === 1"
                        variant="danger"
                        @click="removeAffiliateLevel(i-1)"
                    >
                      <i class="uil uil-trash"></i>
                    </b-button>
                  </b-col>
                </b-row>

                <b-form-group
                    label-cols-sm="12"
                    label-cols-lg="2"
                    label=""
                    label-for="">
                    <b-button type="button" variant="primary" size="sm" @click.prevent="addAffiliateLevel">
                      <i class="uil uil-plus-circle mr-1"></i> เพิ่ม
                    </b-button>
                </b-form-group>
              </template>
            </template>
            <!-- # AF -->

            <!-- Turn setting -->
            <hr class="my-4"/>
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="2"
              :label="$t('cashback.turnover_point')"
            >
              <b-row class="mb-3">
                <b-col>
                  <b-input-group prepend="ประเภทเทิร์น">
                    <b-select
                      id="turnType"
                      v-model="promotionData.turnType"
                    >
                      <b-form-select-option
                        v-for="(opt, key) in turnTypeOptions"
                        :key="key"
                        :value="opt.value"
                      >
                        {{ opt.text }}
                      </b-form-select-option>
                    </b-select>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row v-if="promotionData.turnType !== 2">
                <b-col>
                  <ValidationProvider
                      v-slot="{ errors }"
                      name="ยอดเทิร์น"
                      rules="required|min:1"
                  >
                    <b-input-group prepend="ยอดเทิร์น" append="เท่า">
                      <b-input
                        v-model="promotionData.turnRate"
                        type="number"
                        :state="errors[0] ? false : null"
                      ></b-input>
                    </b-input-group>
                  </ValidationProvider>
                  <b-form-text>
                    ระบบจะนำยอดฝากกับโบนัสเอาไปคูณ เช่นเทิร์น 10 เท่า ฝาก 100
                    โบนัส 50 ลูกค้าต้องทำเทิร์น 1500
                  </b-form-text>
                </b-col>
                <b-col>
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <b-form-checkbox
                          v-model="promotionData.haveMaxWithdraw"
                          name="haveMaxWithdraw"
                          switch
                      >
                      </b-form-checkbox>
                    </b-input-group-prepend>

                    <b-input-group-prepend is-text>
                      อั้นถอน
                    </b-input-group-prepend>

                    <b-input
                      v-model="promotionData.maxWithdraw"
                      type="number"
                      :disabled="!promotionData.haveMaxWithdraw"
                    ></b-input>

                    <template #append>
                      <b-form-select
                          id="maxWithdrawType"
                          v-model="promotionData.maxWithdrawType"
                          style="width: 80px; min-width: 80px"
                          :disabled="!promotionData.haveMaxWithdraw"
                      >
                        <b-form-select-option :value="0">เครดิต</b-form-select-option>
                        <b-form-select-option :value="1">เท่า</b-form-select-option>
                      </b-form-select>
                    </template>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>
            <!-- # Turn setting -->

            <!-- Bonus setting -->
            <hr class="my-4"/>
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="2"
              label="ประเภทโบนัส"
              label-for="bonusType"
            >
              <b-select
                id="bonusType"
                v-model="promotionData.bonusType"
                :disabled="[0,5,6,7].includes(promotionData.type)"
              >
                <b-form-select-option
                  v-for="(opt, key) in bonusOptions"
                  :key="key"
                  :value="opt.value"
                >
                  {{ opt.text }}
                </b-form-select-option>
              </b-select>
            </b-form-group>
            <ValidationProvider
                v-if="[0].includes(promotionData.type)"
                v-slot="{ errors }"
                name="โบนัส"
                rules="required"
            >
              <b-form-group
                  label-cols-sm="12"
                  label-cols-lg="2"
                  label="โบนัส"
                  label-for="bonus"
              >
                <b-input-group>
                  <b-form-input
                      id="bonus"
                      v-model="promotionData.promotionBonuses[0].bonus"
                      type="number"
                      :state="errors[0] ? false : null"
                  ></b-form-input>
                  <b-input-group-append is-text>
                    เครดิต
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </ValidationProvider>
            <b-form-group
                v-else
                label-cols-lg="2" label-cols-sm="12"
                :label="[5,6,7].includes(promotionData.type) ? 'รางวัล' : 'เงื่อนไข'"
            >
              <b-row
                v-for="(item, index) in promotionData.promotionBonuses"
                :key="index"
              >
                <b-col>
                  <b-form-group :label="promotionData.bonusType == 1 ? 'ยอดฝาก' : 'ฝากขั้นต่ำ'">
                    <b-form-input v-model="item.minDeposit"></b-form-input>
                  </b-form-group>
                </b-col>
<!--                <b-col v-if="promotionData.type === 7">-->
<!--                  <b-form-group label="ฝากสูงสุด">-->
<!--                    <b-form-input :v-model="item.maxDeposit || 0"></b-form-input>-->
<!--                    <b-form-text>(ไม่จำกัดใส่ 0)</b-form-text>-->
<!--                  </b-form-group>-->
<!--                </b-col>-->
                <b-col v-if="promotionData.type === 7">
                  <b-form-group label="ฝากต่อเนื่อง">
                    <b-input-group append="วัน">
                      <b-form-input v-model="item.bonusOfTheDay"></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col v-if="promotionData.bonusType === 2">
                  <b-form-group label="%การออก">
                    <b-form-input v-model="item.percentage"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                 <b-col>
                  <b-form-group label="โบนัส">
                    <b-input-group>
                      <b-form-input v-model="item.bonus"></b-form-input>
                      <b-input-group-append is-text>
                        {{ promotionData.bonusType === 0 ? '%' : 'เครดิต' }}
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col v-if="promotionData.bonusType === 0">
                  <b-form-group label="โบนัสสูงสุด">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <b-form-checkbox
                            v-model="item.haveMaxBonus"
                            switch
                        >
                        </b-form-checkbox>
                      </b-input-group-prepend>
                      <b-form-input v-model="item.maxBonus" :disabled="!item.haveMaxBonus"></b-form-input>
                      <b-input-group-append is-text>
                        เครดิต
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col v-if="![0].includes(promotionData.type)" cols="1">
                  <b-form-group :label="$t('buttons.remove')">
                    <b-button
                      :disabled="index === 0"
                      variant="danger"
                      @click="onRemoveConditionSetting(index)"
                    >
                      <i class="uil uil-trash"></i>
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button
                  v-if="![0].includes(promotionData.type)"
                  size="sm"
                  @click="onAddCondition"
              >
                <i class="uil uil-plus"></i>
                {{ $t('buttons.add') }}
              </b-button>
            </b-form-group>
            <!-- # Turn setting -->

            <hr class="my-4"/>
            <b-form-group
                abel-cols-sm="12"
                label-cols-lg="2"
                label-for="isActive"
            >
              <b-input-group>
                <b-form-checkbox
                    v-model="promotionData.isActive"
                    name="isActive"
                    switch
                >
                  เปิดใช้งานโปรโมชั่น
                </b-form-checkbox>
              </b-input-group>
            </b-form-group>
            <b-form-group
              abel-cols-sm="12"
              label-cols-lg="2"
              label-for="isHide"
            >
              <b-input-group>
                <b-form-checkbox
                  v-model="promotionData.isHide"
                  name="isHide"
                  switch
                >
                  แสดงหน้าโปรโมชั่น
                </b-form-checkbox>
              </b-input-group>
            </b-form-group>
            <div class="text-right">
              <b-button class="mr-2" to="/promotions/list" variant="light">
                {{ $t('buttons.back') }}
              </b-button>
              <b-overlay
                :show="isAdding || isUpdating"
                rounded
                spinner-small
                class="d-inline-block"
              >
                <b-button type="submit" variant="primary" block>
                  {{ $t('buttons.save') }}
                </b-button>
              </b-overlay>
            </div>
          </form>
        </ValidationObserver>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {VueEditor} from 'vue2-editor'
import {mapActions, mapGetters, mapState} from 'vuex'
import dayjs from 'dayjs';

export default {
  page: {
    title: 'รายละเอียดโปรโมชั่น',
  },
  components: {
    VueEditor,
    MasterSelectInput: () => import('@components/master/master-select-input'),
    AgentsSelectInput: () => import('@components/agents/agents-select-input'),
    RepeatConfig: () => import('@components/promotions/repeat-config'),
  },
  data() {
    return {
      masterId: null,
      startTimeRefill: null,
      endTimeRefill: null,
      repeatConfig: {
        day: '0',
        startTime: null,
        endTime: null,
      },
      // ประเภทโปร (type)
      // เครดิตฟรี = 0
      // ฝากครั้งแรก = 1
      // ฝากครั้งแรกของวัน = 2
      // ทุกยอดฝาก = 3
      // นาทีทอง = 4
      // กงล้อ = 5
      // เปิดไพ่ = 6
      promotionOptions: [
        { text: 'เครดิตฟรี', value: 0 },
        { text: 'ฝากครั้งแรก (สมาชิกใหม่)', value: 1 },
        { text: 'ฝากครั้งแรกของวัน', value: 2 },
        { text: 'ทุกยอดฝาก', value: 3 },
        { text: 'นาทีทอง', value: 4 },
        { text: 'กงล้อ', value: 5 },
        { text: 'เปิดไพ่', value: 6 },
        { text: 'ฝากต่อเนื่อง', value: 7 },
        { text: 'Welcome Back', value: 8 },
        { text: 'โบนัสพิเศษ', value: 10 },
      ],
      bonusNameOptions: [
        { text: 'สงกรานต์', value: 'songkran' },
      ],
      // เกม (forGameType)
      // ทุกเกม = 0
      // สล็อต = 1
      // ยิงปลา = 2
      // คาสิโน = 3
      // กีฬา = 4
      gameTypeOptions: [
        { text: 'ทุกเกม', value: 0 },
        { text: 'สล็อต', value: 1 },
        { text: 'ยิงปลา', value: 2 },
        { text: 'คาสิโน', value: 3 },
        { text: 'กีฬา', value: 4 },
      ],
      // ประเภทโบนัส (bonusType)
      // เปอร์เซ็นต์ = 0
      // ค่าคงที่ = 1
      // สุ่ม = 2
      bonusOptions: [
        { text: 'เปอร์เซ็นต์', value: 0 },
        { text: 'ค่าคงที่', value: 1 },
        { text: 'สุ่ม', value: 2 },
      ],
      // ประเภทเทิร์น (turnType)
      // เทิร์นเครดิต = 0
      // เทิร์นโอเวอร์ = 1
      // ไม่มีเทิร์น = 2
      turnTypeOptions: [
        { text: 'เทิร์นเครดิต', value: 0 },
        { text: 'เทิร์นโอเวอร์', value: 1 },
        { text: 'ไม่มีเทิร์น', value: 2 },
      ],
      promotionData: {
        title: '',
        description: '',
        image: null,
        type: 0,
        conditionType: 0,
        maxDeposit: 0,
        minDeposit: 0,
        forGameType: 0,
        bonusType: 0,
        bonusName: null,
        stackDayCount: 1,
        turnType: 0,
        turnRate: null,
        maxWithdraw: null,
        maxWithdrawType: 1,
        haveMaxWithdraw: true,
        startMin: 0,
        startHour: 0,
        endHour: 0,
        endMin: 0,
        dailyLimit: null,
        dailyLimitPerDay: null,
        isDailyLimit: false,
        isDailyLimitPerDay: false,
        isHide: false,
        isActive: false,
        startTime: 9,
        endTime: 9,
        promotionBonuses: [
          {
            bonus: 100,
            haveMaxBonus: false,
            maxBonus: 0,
            minDeposit: 100,
            maxDeposit: 0,
            percentage: 0,
            bonusOfTheDay: 3,
          }
        ],
        agentId: '',
        isEnableDepositAffiliate: false,
        affiliateCommissionType: null,
        affiliateCommissionRate: [],
        maxAffiliateCommission: [],
        isRepeat: false,
        repeatConfig: {'0':[],'1':[],'2':[],'3':[],'4':[],'5':[],'6':[],'7':[]},
      },
    }
  },
  computed: {
    dayjs() {
      return dayjs
    },
    ...mapState({
      isFetching: (state) => state.promotion.isFetchingPromotion,
      isAdding: (state) => state.promotion.isAddingPromotion,
      isUpdating: (state) => state.promotion.isUpdatingPromotion,
    }),
    ...mapGetters(['promotionDetail', 'userInfo', 'isAgent', 'isOwner', 'isMaster']),
    isLoading() {
      return false
    },
    promotionId() {
      return this.$route.params.id === 'create' ? null : this.$route.params.id
    },
    daySelect() {
      return [
        { value: '0', text: 'อาทิตย์' },
        { value: '1', text: 'จันทร์' },
        { value: '2', text: 'อังคาร' },
        { value: '3', text: 'พุธ' },
        { value: '4', text: 'พฤหัสบดี' },
        { value: '5', text: 'ศุกร์' },
        { value: '6', text: 'เสาร์' },
      ]
    }
  },
  watch: {
    userInfo() {
      this.setData()
    },
    'promotionData.type'(val){
      if(val !== 10) {
        this.promotionData.bonusName = null
      }
      // กงล้อ เปิด ไพ่ ฟิกไว้ว่าต้องสุ่มอย่างเดียว
      if(val === 0) { // เครดิตฟรี เป็นค่างคงที่
        this.promotionData.bonusType = 1
        this.promotionData.forGameType = 0
        this.promotionData.promotionBonuses = [
          {
            bonus: 100,
            haveMaxBonus: false,
            maxBonus: 0,
            minDeposit: 0,
            maxDeposit: 0,
            percentage: 0,
            bonusOfTheDay: 3,
          }
        ]
      } else if(val === 5 || val === 6) { // เปิดไพ่ วงล้อ สุ่มโบนัส
        this.promotionData.bonusType = 2
      } else if(val === 7) { // เช็คอินเป็นค่าคงที่
        this.promotionData.bonusType = 1
        this.promotionData.promotionBonuses = [
          {
            bonus: 100,
            haveMaxBonus: false,
            maxBonus: 0,
            minDeposit: 100,
            maxDeposit: 0,
            percentage: 0,
            bonusOfTheDay: 3,
          }
        ]
      } else {
        this.promotionData.bonusType = 0
      }
    },
    promotionDetail(val) {
      if (val && !this.promotionData.id) {
        this.setData()
      }
    },
    startTimeRefill(time) {
      if (time) {
        this.promotionData.startHour = time.split(':')[0]
        this.promotionData.startMin = time.split(':')[1]
      }
    },
    endTimeRefill(time) {
      if (time) {
        this.promotionData.endHour = time.split(':')[0]
        this.promotionData.endMin = time.split(':')[1]
      }
    },
  },
  async created() {
    if (this.promotionId) {
      await this.fetchPromotion(this.promotionId)
    }
    this.setData()
    if (this.isAgent) {
      this.promotionData.agentId = this.userInfo.agentId
    }
  },
  methods: {
    ...mapActions(['fetchPromotion', 'addPromotion', 'updatePromotion']),
    addAffiliateLevel() {
      this.promotionData.affiliateCommissionRate.push({ value: null })
      this.promotionData.maxAffiliateCommission.push({ value: null })
    },
    removeAffiliateLevel(i) {
      this.promotionData.affiliateCommissionRate.splice(i, 1)
      this.promotionData.maxAffiliateCommission.splice(i, 1)
    },
    setData() {
      if (this.promotionId && this.promotionDetail) {
        const data = JSON.parse(JSON.stringify(this.promotionDetail))

        this.promotionData = { ...this.promotionData, ...data }

        this.startTimeRefill = `${
          String(data.startHour).length === 1
            ? `0${data.startHour}`
            : data.startHour
        }:${
          String(data.startMin).length === 1
            ? `0${data.startMin}`
            : data.startMin
        }:00`
        this.endTimeRefill = `${data.endHour}:${data.endMin}:00`
        this.endTimeRefill = `${
          String(data.endHour).length === 1 ? `0${data.endHour}` : data.endHour
        }:${
          String(data.endMin).length === 1 ? `0${data.endMin}` : data.endMin
        }:00`

        if(data.maxWithdraw) {
          this.promotionData.maxWithdraw = Number(data.maxWithdraw)
          this.promotionData.haveMaxWithdraw = true
        } else {
          this.promotionData.haveMaxWithdraw = !!data.maxWithdraw
          this.promotionData.maxWithdrawType = 1;
        }

        this.promotionData.isDailyLimit = !!data.dailyLimit
        this.promotionData.isDailyLimitPerDay = !!data.dailyLimitPerDay

        this.promotionData.isEnableDepositAffiliate = data.affiliateCommissionType !== null
        if(this.promotionData.isEnableDepositAffiliate) {
          this.promotionData.affiliateCommissionType = data.affiliateCommissionType
          this.promotionData.affiliateCommissionRate = data.affiliateCommissionRate
          this.promotionData.maxAffiliateCommission = data.maxAffiliateCommission
        }

        if(data.promotionBonuses && data.promotionBonuses.length > 0){
          this.promotionData.promotionBonuses = data.promotionBonuses.map((prop) => {
            if(!prop.maxBonus) {
              prop.maxBonus = 0
            }
            return {
              ...prop,
              haveMaxBonus: !!prop.maxBonus,
            }
          })
        } else {
          this.promotionData.promotionBonuses = [{
            bonus: 100,
            haveMaxBonus: true,
            maxBonus: 100,
            minDeposit: 100,
            maxDeposit: 100,
            percentage: 0,
            bonusOfTheDay: 3,
          }]
        }

        if(!data.dailyLimit) {
          this.promotionData.dailyLimit = 0;
        }

        if(!data.dailyLimitPerDay) {
          this.promotionData.dailyLimitPerDay = 0;
        }
      } else {
        this.startTimeRefill = '10:00:00'
        this.endTimeRefill = '12:00:00'
        this.promotionData = {
          title: '',
          description: '',
          type: 0,
          conditionType: 0,
          maxDeposit: 0,
          minDeposit: 0,
          forGameType: 0,
          bonusType: 1,
          bonusName: null,
          turnType: 0,
          turnRate: null,
          maxWithdraw: null,
          haveMaxWithdraw: false,
          maxWithdrawType: 1,
          stackDayCount: 1,
          dailyLimit: null,
          isDailyLimit: false,
          dailyLimitPerDay: null,
          isDailyLimitPerDay: false,
          image: null,
          promotionBonuses: [
            {
              bonus: 100,
              haveMaxBonus: false,
              maxBonus: 0,
              minDeposit: 100,
              maxDeposit: 0,
              percentage: 0,
              bonusOfTheDay: 3,
            },
          ],
          startMin: 0,
          startHour: 0,
          endHour: 0,
          endMin: 0,
          agentId: '',
          isEnableDepositAffiliate: false,
          affiliateCommissionType: 0,
          affiliateCommissionRate: [{ value: null }],
          maxAffiliateCommission: [{ value: null }],
          isRepeat: false,
          repeatConfig: {'0':[],'1':[],'2':[],'3':[],'4':[],'5':[],'6':[],'7':[]},
        }
      }
    },
    onSubmit() {
      this.promotionData.turnRate = +this.promotionData.turnRate
      if (this.isAgent) {
        this.promotionData.agentId = this.userInfo.agentId
      }
      if (this.promotionId) {
        this.updatePromotion({
          id: this.promotionId,
          data: this.promotionData,
        })
      } else {
        this.addPromotion(this.promotionData)
      }
    },
    onRemoveConditionSetting(index = 0) {
      this.promotionData.promotionBonuses.splice(index, 1)
    },
    onAddCondition() {
      this.promotionData.promotionBonuses.push({
        bonus: 100,
        haveMaxBonus: false,
        maxBonus: 0,
        minDeposit: 100,
        maxDeposit: 0,
        percentage: 0,
        bonusOfTheDay: 3,
      })
    },
    onAgentIdChange(agentId) {
      this.promotionData.agentId = agentId
    },
    onMasterIdChange(masterId) {
      this.masterId = masterId
    },
  },
}
</script>
